<template>
    <div>
        <h4 class="bold text-uppercase">THống kê tổng thể</h4>
        <div class="row mt-3">
            <div class="col-md-2 mb-3 col-6">
                <div class="card card-info h-100">
                    <div class="card-body text-center px-1">
                        <h6 class="bold">TỔNG TIỀN USER</h6>
                        <h5 class="bold font-18">
                            <number
                                ref="number1"
                                :from="0"
                                :to="site_total_coin_in_site"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power1.easeOut"
                            />
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mb-3 col-6">
                <div class="card card-info h-100">
                    <div class="card-body text-center px-1">
                        <h6 class="bold">TỔNG CHI SITE GỐC</h6>
                        <h5 class="bold font-18">
                            <number
                                ref="number1"
                                :from="0"
                                :to="site_total_out_from_admin"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power1.easeOut"
                            />
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mb-3 col-6">
                <div class="card card-info h-100">
                    <div class="card-body text-center px-1">
                        <h6 class="bold">TỔNG NẠP</h6>
                        <h5 class="bold font-18">
                            <number
                                ref="number1"
                                :from="0"
                                :to="site_total_paid"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power1.easeOut"
                            />
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mb-3 col-6">
                <div class="card card-info h-100">
                    <div class="card-body text-center px-1">
                        <h6 class="bold">TỔNG CHI</h6>
                        <h5 class="bold font-18">
                            <number
                                ref="number1"
                                :from="0"
                                :to="site_total_used"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power1.easeOut"
                            />
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mb-3 col-6">
                <div class="card card-info h-100">
                    <div class="card-body text-center px-1">
                        <h6 class="bold">TỔNG HOÀN</h6>
                        <h5 class="bold font-18">
                            <number
                                ref="number1"
                                :from="0"
                                :to="site_total_refund"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power1.easeOut"
                            />
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mb-3 col-6">
                <div class="card card-info h-100">
                    <div class="card-body text-center px-1">
                        <h6 class="bold">TỔNG DOANH THU</h6>
                        <h5 class="bold font-18">
                            <number
                                ref="number1"
                                :from="0"
                                :to="site_total_revenue"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power1.easeOut"
                            />
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <h4 class="bold text-uppercase pt-2">Thống kê theo thời gian</h4>
        <div :class="['row my-2 align-items-center']">
            <!-- <div class="col-md-auto">Chọn khoảng thời gian cần xem</div> -->
            <div class="col-md-auto text-left">
                <div class="card card-gray">
                    <div class="card-body py-0 px-2">
                        <div class="row">
                            <div class="col-md-auto col-6 my-2">
                                <h6 class="font-bold mb-0"><i class="fas fa-calendar-alt"></i></h6>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <datetime
                                    class="theme-green"
                                    type="date"
                                    v-model="startDate"
                                    format="dd/MM/yyyy"
                                    input-class="text-center input-light"
                                    :min-datetime="minDate"
                                    value-zone="Asia/Ho_Chi_Minh"
                                ></datetime>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <h6 class="font-bold mb-0">đến</h6>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <datetime
                                    class="theme-green"
                                    type="date"
                                    v-model="endDate"
                                    format="dd/MM/yyyy"
                                    input-class="text-center input-light"
                                    :min-datetime="startDate"
                                    value-zone="Asia/Ho_Chi_Minh"
                                ></datetime>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-auto bold text-danger">Thống kê chính xác kể từ 28/02/2020</div>
        </div>
        <sales :chart-data="chartData"></sales>
        <div class="card my-3 card-white">
            <div class="card-body p-3">
                <div class="row justify-content-center">
                    <div class="col-md-auto col-6 bold text-dark">
                        Chi site gốc:
                        <number
                            ref="number1"
                            :from="0"
                            :to="total_out_from_admin"
                            :format="theFormat"
                            :duration="1"
                            :delay="0"
                            easing="Power1.easeOut"
                        />
                    </div>
                    <div class="col-md-auto col-6 bold cl-green">
                        Tổng nạp:
                        <number
                            ref="number1"
                            :from="0"
                            :to="total_paid"
                            :format="theFormat"
                            :duration="1"
                            :delay="0"
                            easing="Power1.easeOut"
                        />
                    </div>
                    <div class="col-md-auto col-6 bold orange">
                        Tổng chi:
                        <number
                            ref="number1"
                            :from="0"
                            :to="total_used"
                            :format="theFormat"
                            :duration="1"
                            :delay="0"
                            easing="Power1.easeOut"
                        />
                    </div>
                    <div class="col-md-auto col-6 bold red">
                        Tổng hoàn:
                        <number
                            ref="number1"
                            :from="0"
                            :to="total_refund"
                            :format="theFormat"
                            :duration="1"
                            :delay="0"
                            easing="Power1.easeOut"
                        />
                    </div>
                    <div class="col-md-auto col-6 bold mega">
                        Doanh thu:
                        <number
                            ref="number1"
                            :from="0"
                            :to="total_revenue"
                            :format="theFormat"
                            :duration="1"
                            :delay="0"
                            easing="Power1.easeOut"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStatisticsRevenue, getStatisticsAll } from "../../../api/admin"
import moment from "moment"
import sales from "../../../components/chart/sales-chart"
export default {
    name: "Statistics",
    components: {
        sales
    },
    data() {
        return {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString(),
            chartData: {},
            data: [],
            total_used: 0,
            total_paid: 0,
            total_refund: 0,
            total_out_from_admin: 0,
            total_coin_in_site: 0,
            total_revenue: 0,
            data_result: [],
            load_data: false,
            site_total_used: 0,
            site_total_paid: 0,
            site_total_out_from_admin: 0,
            site_total_revenue: 0,
            site_total_coin_in_site: 0,
            site_total_refund: 0,
            minDate: new Date(2020, 1, 28).toISOString()
        }
    },
    watch: {
        startDate() {
            if (!this.load_data) {
                this.getPayment()
            }
        },
        endDate() {
            if (!this.load_data) {
                this.getPayment()
            }
        }
    },
    async created() {
        this.getStatistics()
        this.load_data = true
        await this.getPayment()
        this.load_data = false
    },
    methods: {
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : 0
        },
        returnDate: function(myDate) {
            myDate = new Date(myDate)
            return moment(myDate).format("YYYY-MM-DD")
        },
        getStatistics: async function() {
            let data = await getStatisticsAll()
            if (data.status == 200 && data.success) {
                this.site_total_used = data.data.total_used
                this.site_total_paid = data.data.total_paid
                this.site_total_out_from_admin = data.data.total_out_from_admin
                this.site_total_revenue = data.data.total_revenue
                this.site_total_coin_in_site = data.data.total_coin_in_site
                this.site_total_refund = data.data.total_refund
            }
        },
        getPayment: async function() {
            let data = await getStatisticsRevenue(this.returnDate(this.startDate), this.returnDate(this.endDate))
            if (data.success) {
                this.data = data.data
                this.total_used = data.total_used
                this.total_paid = data.total_paid
                this.total_revenue = data.total_revenue
                this.total_refund = data.total_refund
                this.total_coin_in_site = data.total_coin_in_site
                this.total_out_from_admin = data.total_out_from_admin
                this.createChart()
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", JSON.stringify(data), "error")
                }
            }
        },
        createChartData: function() {
            let labelArray = []
            let labelUsed = []
            let labelPaid = []
            let labelRevenue = []
            let labelRefund = []
            let labelAdmin = []
            for (const data of this.data) {
                for (const prop in data) {
                    labelArray.push(moment(prop).format("DD-MM-YYYY"))
                    labelPaid.push(data[prop].paid)
                    labelUsed.push(data[prop].used)
                    labelRevenue.push(data[prop].revenue)
                    labelRefund.push(data[prop].refund)
                    labelAdmin.push(data[prop].coin_used_admin)
                }
            }
            return { labelPaid, labelUsed, labelRevenue, labelArray, labelRefund, labelAdmin }
        },
        createChart: async function() {
            this.chartData = {}
            let chartData = this.createChartData()
            this.chartData = {
                labels: chartData.labelArray,
                datasets: [
                    {
                        type: "scatter",
                        label: "Chi site gốc",
                        backgroundColor: "#000000",
                        borderColor: "#000000",
                        hoverBackgroundColor: "#000000",
                        borderWidth: 2,
                        data: chartData.labelAdmin,
                        fill: false,
                        showLine: true
                    },
                    {
                        type: "scatter",
                        label: "Tiền nạp",
                        backgroundColor: "#1FAB89",
                        borderColor: "#1FAB89",
                        hoverBackgroundColor: "#1FAB89",
                        borderWidth: 2,
                        data: chartData.labelPaid,
                        fill: false,
                        showLine: true
                    },
                    {
                        type: "scatter",
                        label: "Tiền chi",
                        backgroundColor: "#FFB900",
                        borderColor: "#FFB900",
                        hoverBackgroundColor: "#FFB900",
                        borderWidth: 2,
                        data: chartData.labelUsed,
                        fill: false,
                        showLine: true
                    },
                    {
                        type: "scatter",
                        label: "Tiền hoàn",
                        backgroundColor: "#e46a76",
                        borderColor: "#e46a76",
                        hoverBackgroundColor: "#e46a76",
                        borderWidth: 2,
                        data: chartData.labelRefund,
                        fill: false,
                        showLine: true
                    },
                    {
                        type: "scatter",
                        label: "Doanh thu",
                        backgroundColor: "#007DC4",
                        borderColor: "#007DC4",
                        hoverBackgroundColor: "#007DC4",
                        borderWidth: 2,
                        data: chartData.labelRevenue,
                        fill: false,
                        showLine: true
                    }
                ]
            }
        },
        selectFilter: function(item) {
            this.filters = item
        }
    }
}
</script>

<style scoped></style>
