<template>
    <div>
        <statisticsrevenue></statisticsrevenue>
        <!-- <statisticstools></statisticstools> -->
        <statisticsuser></statisticsuser>
    </div>
</template>
<script>
import statisticsrevenue from "./statistics-revenue"
// import statisticstools from "./statistics-tools"
import statisticsuser from "./statistics-user"

export default {
    name: "statistics",
    components: {
        statisticsrevenue,
        // statisticstools,
        statisticsuser
    },
    data() {
        return {}
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        }
    },
    async created() {},
    methods: {}
}
</script>
