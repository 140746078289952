<template>
    <div class="pb-5">
        <h4 class="bold text-uppercase pt-2">Thống kê User Theo Thời Gian</h4>
        <div :class="['row my-2 mb-4 align-items-center']">
            <!-- <div class="col-md-auto">Chọn khoảng thời gian cần xem</div> -->
            <div class="col-md-auto text-left">
                <div class="card card-gray">
                    <div
                        class="card-body py-0 px-2 flex-column flex-lg-row align-items-lg-center"
                        style="display: flex"
                    >
                        <div class="row">
                            <div class="col-md-auto col-6 my-2">
                                <h6 class="font-bold mb-0"><i class="fas fa-calendar-alt"></i></h6>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <datetime
                                    class="theme-green"
                                    type="date"
                                    v-model="startDate"
                                    format="dd/MM/yyyy"
                                    input-class="text-center input-light"
                                    :min-datetime="minDate"
                                    value-zone="Asia/Ho_Chi_Minh"
                                ></datetime>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <h6 class="font-bold mb-0">đến</h6>
                            </div>
                            <div class="col-md-auto col-6 my-2">
                                <datetime
                                    class="theme-green"
                                    type="date"
                                    v-model="endDate"
                                    format="dd/MM/yyyy"
                                    input-class="text-center input-light"
                                    :min-datetime="startDate"
                                    value-zone="Asia/Ho_Chi_Minh"
                                ></datetime>
                            </div>
                        </div>
                        <div class="row ml-lg-3 my-2">
                            <div class="col">
                                <button
                                    class="btn btn-xs btn-success font-bold"
                                    @click="getStatisticsUsers({ loadAll: true })"
                                >
                                    Load toàn bộ thời gian
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-auto text-right">
                <div class="row align-items-center text-right">
                    <div class="col-md col-12">
                        <h6 class="mb-0">Sắp xếp theo:</h6>
                    </div>
                    <div class="col-md-auto col-12">
                        <select @change="getStatisticsUsers()" v-model="type" class="custom-select">
                            <option value="users">Tiền hiện tại</option>
                            <option value="paid">Tiền nạp</option>
                            <option value="used">Tiền sử dụng</option>
                            <option value="refund">Tiền hoàn</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md text-right">
                <div class="row align-items-center text-right">
                    <div class="col-md col-12">
                        <h6 class="mb-0">Số lượng hiển thị:</h6>
                    </div>
                    <div class="col-md-auto col-12">
                        <select @change="getStatisticsUsers()" v-model="limit" class="custom-select">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable :header="false" stt="true" :columns="columnsTableLogs" :rows="data"> </datatable>
    </div>
</template>

<script>
import { getStatisticsUser } from "../../../api/admin"
import datatable from "../../../components/datatable/datatable"
import moment from "moment"
export default {
    name: "StatisticsUser",
    components: { datatable },
    data() {
        return {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString(),
            data: [],
            type: "users",
            limit: 50,
            load_data: false,
            columnsTableLogs: [
                { label: "Username", field: "username" },
                { label: "Tiền hiện tại", field: "coin", numeric: true },
                { label: "Tiền nạp", field: "paid", numeric: true },
                { label: "Tiền sử dụng", field: "used", numeric: true },
                { label: "Tiền hoàn", field: "refund", numeric: true }
            ],
            minDate: new Date(2020, 1, 28).toISOString()
        }
    },
    computed: {
        tools() {
            return this.$store.state.tools.tools
        }
    },
    watch: {
        startDate() {
            if (!this.load_data) {
                this.getStatisticsUsers()
            }
        },
        endDate() {
            if (!this.load_data) {
                this.getStatisticsUsers()
            }
        }
    },
    async created() {
        this.load_data = true
        await this.getStatisticsUsers()
        this.load_data = false
    },
    methods: {
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : 0
        },
        returnDate: function(myDate) {
            myDate = new Date(myDate)
            return moment(myDate).format("YYYY-MM-DD")
        },
        getStatisticsUsers: async function(options = {}) {
            let data
            if (options.loadAll) {
                data = await getStatisticsUser("2020-02-28", this.returnDate(new Date()), this.type, this.limit)
            } else {
                data = await getStatisticsUser(
                    this.returnDate(this.startDate),
                    this.returnDate(this.endDate),
                    this.type,
                    this.limit
                )
            }
            if (data.success) {
                this.data = data.data
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", JSON.stringify(data), "error")
                }
            }
        }
    }
}
</script>

<style scoped></style>
